<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <FinishedBanner v-if="finished"></FinishedBanner>
          <GameEnd
            v-if="showScore"
          ></GameEnd>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    FinishedBanner: () => import(
    /* webpackChunkName: "component-mini-game-finished-banner" */ "@/components/games/FinishedBanner"),
    GameEnd: () => import(
    /* webpackChunkName: "component-mini-game-score" */ "@/components/dailyGame/GameEnd.vue"),
   
},
  computed: mapState({
    settings: (state) => state.settings.data,
    event: (state) => state.event.data,
    boss: (state) => state.boss.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    finished: true,
    showScore: false,
    schoolYear:null,
    classroomMonth: 3,
    score: 200,
    ladderApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    submissionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    this.gameApi.url =
      this.$api.servers.game +
      "/api/v2/" +
      this.$_getLocale() +
      "/game/dailyGame/move";

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };

    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
    };
    this.gameApi.callbackSuccess = (resp) => {
      this.gameApi.isLoading = false;
      if(this.auth.Player.userType != "adult"){
        this.submission(resp.Game.currentFloor, resp.Game.currentTile)
      }
      this.next()
      
    };
    this.ladderApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/footprint/spend";

    this.ladderApi.callbackReset = () => {
      this.ladderApi.isLoading = true;
      this.ladderApi.isError = false;
    };

    this.ladderApi.callbackError = (e) => {
      this.ladderApi.isLoading = false;
      this.ladderApi.isError = true;
      this.ladderApi.error = e;
      this.errorMessage =
          "Not enough ladder. Come back and collect your new ladders tomorrow.";
        this.error = true;
        
    };
    this.ladderApi.callbackSuccess = () => {
      this.ladderApi.isLoading = false;
      this.callbackOk()
    };
  },
  mounted() {
    if (this.auth.Classroom) {
      this.schoolYear =this.auth.Classroom.schoolYear;
        this.classroomMonth = this.auth.Classroom.month
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
        this.classroomMonth = this.auth.User.month
    }
    setTimeout(() => {
      this.finished = false;
      //this.showScore = true;
      this.gameApi.params = {
      schoolYear: this.schoolYear,
      classroomMonth: this.classroomMonth
    };
     
    this.$api.fetch(this.gameApi);
    
      this.ladderApi.params = {
        quantity: 1,
    };
      this.$api.fetch(this.ladderApi);
    }, 3000);
  },
  methods: {
    submission(floor,tile) {
      this.submissionApi.url =
        this.$api.servers.log +
        "/api/v1/" +
        this.$_getLocale() +
        "/dailyGameProgress/add";
      this.submissionApi.callbackReset = () => {
        this.submissionApi.isLoading = true;
        this.submissionApi.isError = false;
        this.submissionApi.error = null;
      };
      this.submissionApi.callbackError = (e) => {
        this.submissionApi.isLoading = false;
        this.submissionApi.isError = true;
        this.submissionApi.error = e;
      };
      this.submissionApi.callbackSuccess = () => {
        this.submissionApi.isLoading = false;
      };
      this.submissionApi.params = {
        institutionId: this.auth.Institution?this.auth.Institution.id : null,
          classroomId: this.auth.Classroom?this.auth.Classroom.id : null,
        studentId: this.auth.Classroom? this.auth.User.id : null,
        userId: this.auth.Classroom?null:this.auth.User.id,
        schoolYear: this.schoolYear,
        mochiId: this.auth.Player.mochiId,
        currentFloor: floor,
        currentTile: tile,
      };
      this.$api.fetch(this.submissionApi);
    },
    next() {
      this.$router.push({
        name: "PageExploreDailyGame",
      });
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>